<template>
  <div class="exhibition-3d"> <!-- body -->

    <!-- <svg class="hidden">
      <symbol id="icon-menu" viewBox="0 0 24 24">
        <title>menu</title>
        <path d="M24,5.8H0v-2h24V5.8z M19.8,11H4.2v2h15.6V11z M24,18.2H0v2h24V18.2z"/>
      </symbol>
      <symbol id="icon-cross" viewBox="0 0 24 24">
        <title>cross</title>
        <path d="M13.4,12l7.8,7.8l-1.4,1.4l-7.8-7.8l-7.8,7.8l-1.4-1.4l7.8-7.8L2.7,4.2l1.4-1.4l7.8,7.8l7.8-7.8l1.4,1.4L13.4,12z"/>
      </symbol>
      <symbol id="icon-info" viewBox="0 0 20 20">
        <title>info</title>
        <circle style="fill:#fff" cx="10" cy="10" r="9.1"/>
        <path d="M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0z M10,18.6c-4.7,0-8.6-3.9-8.6-8.6S5.3,1.4,10,1.4s8.6,3.9,8.6,8.6S14.7,18.6,10,18.6z M10.7,5C10.9,5.2,11,5.5,11,5.7s-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3C9.1,6.2,9,6,9,5.7S9.1,5.2,9.3,5C9.5,4.8,9.7,4.7,10,4.7C10.3,4.7,10.5,4.8,10.7,5z M9.3,8.3h1.4v7.2H9.3V8.3z"/>
      </symbol>
    </svg> -->

    <div class="container">
      <div class="scroller">
        <div class="room room--current">
          <div class="room__side room__side--back">
            <img class="room__img" src="/public-images/exhibition/14.png" alt="Hashmask"/>
            <img class="room__img" src="/public-images/exhibition/3-ape_compressed.jpg" alt="Hashmask"/>
            <img class="room__img" src="/public-images/exhibition/15.png" alt="Hashmask"/>
          </div>
          <div class="room__side room__side--left">
            <img class="room__img" src="/public-images/exhibition/11-ape_compressed.jpg" alt="Hashmask"/>
            <img class="room__img" src="/public-images/exhibition/9_compressed.jpg" alt="Hashmask"/>
          </div>
          <div class="room__side room__side--right">
            <img class="room__img" src="/public-images/exhibition/11-ape_compressed.jpg" alt="Hashmask"/>
            <img class="room__img" src="/public-images/exhibition/9_compressed.jpg" alt="Hashmask"/>

          </div>
          <div class="room__side room__side--bottom"></div>
        </div><!-- /room -->
      </div>
    </div><!-- /container -->
    <div class="content">
      <header class="codrops-header">
        <!-- <h1 class="codrops-header__title">3D Room Exhibition</h1> -->
        <!-- <div class="subject">モダンアート</div> -->
        
        <!-- <button class="btn btn--info btn--toggle" style="display: none;">
          <svg class="icon icon--info"><use xlink:href="#icon-info"></use></svg>
          <svg class="icon icon--cross"><use xlink:href="#icon-cross"></use></svg>  
        </button>

        <button class="btn btn--menu btn--toggle" style="display: none;">
          <svg class="icon icon--menu"><use xlink:href="#icon-menu"></use></svg>
          <svg class="icon icon--cross"><use xlink:href="#icon-cross"></use></svg>
        </button> -->

        <!-- <div class="overlay overlay--menu">
          <ul class="menu">
            <li class="menu__item menu__item--current"><a class="menu__link" href="#">Exhibitions</a></li>
            <li class="menu__item"><a class="menu__link" href="#">Discover</a></li>
            <li class="menu__item"><a class="menu__link" href="#">Visit us</a></li>
            <li class="menu__item"><a class="menu__link" href="#">Shop</a></li>
          </ul>
        </div>
        <div class="overlay overlay--info">
          <p class="info">&ldquo;Life in Pieces&rdquo; is the subject of all exhibitions taking place in the Mirai Art Gallery in 2017. Fragments of lost memories, fleeting moments and the breaking apart of human nature are this year's highlighted topics. We welcome you to a exploration space of a unique kind&mdash;the one that will stay with you and impact you on many levels. Come visit us.</p>
        </div> -->
      </header>

      <!-- <h4 class="location">Mirai Art Gallery &amp; Exhibition Center, Sapporo, Japan</h4> -->

      <div class="slides">

        <p class="slide__collab-text">Hashmasks x BAYC communities collab</p>

        <div class="slide">
          <h2 class="slide__name">
            Bored Hash <br/> <span>Galleries</span>
            <!-- Hashgalleries -->
          </h2>
          <h3 class="slide__title">
            <span>The first dynamic NFT fully powered by Hashmasks and BAYC.</span>
            <!-- <div class="slide__number">Room <strong>Tenjin</strong></div> -->
          </h3>
          <p class="slide__date" style="display: none;"></p>
          <!-- <p class="slide__date" style="display: none;">25 Mar – 11 May 2017</p> -->
          <!-- <p class="slide__date">The first dynamic NFT fully powered by Hashmasks</p> -->
        </div>
      </div>

      <!-- <nav class="nav">
        <button class="btn btn--nav btn--nav-left">
          <svg class="nav-icon nav-icon--left" width="42px" height="12px" viewBox="0 0 70 20">
            <path class="nav__triangle" d="M52.5,10L70,0v20L52.5,10z"/>
            <path class="nav__line" d="M55.1,11.4H0V8.6h55.1V11.4z"/>
          </svg>
        </button>
        <button class="btn btn--nav btn--nav-right">
          <svg class="nav-icon nav-icon--right" width="42px" height="12px" viewBox="0 0 70 20">
            <path class="nav__triangle" d="M52.5,10L70,0v20L52.5,10z"/>
            <path class="nav__line" d="M55.1,11.4H0V8.6h55.1V11.4z"/>
          </svg>
        </button>
      </nav> -->

    </div><!-- /content -->

    <div class="overlay overlay--loader overlay--active">
      <div class="loader">
        <!-- <MorphingGooeyAnimation :delay="500" :speed="0.5" :initial-delay="0">
          <svg viewBox="0 0 110 20" preserveAspectRatio="xMinYMid meet">
            <defs>
                <filter id="goo-1">
                  <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur"></feGaussianBlur>
                  <feColorMatrix in="blur" mode="matrix" values=" 1 0 0 0 0  
                                                                  0 1 0 0 0  
                                                                  1 0 1 0 0  
                                                                  0 0 0 16 -7" result="goo"></feColorMatrix>
                  <feComposite in="SourceGraphic" in2="goo" operator="atop"></feComposite>
                </filter>
              </defs>
              <g>
                <image href="/public-images/masks/2.png" x="50%" y="50%" width="50" height="69" transform="translate(-25,-34.5)" />
                <image href="/public-images/masks/5.png" x="50%" y="50%" width="50" height="69" transform="translate(-25,-34.5)" />
              </g>
          </svg>
        </MorphingGooeyAnimation> -->

        <img class="loader__mask-icon" src="/public-images/masks/hashmask-ape2.png" />
        <!-- <img class="loader__mask-icon" src="/public-images/masks/2.png" /> -->
        <!-- <img class="loader__mask-icon" src="/public-images/ape-icon.png" /> -->

        <!--
          <div class="loader__square"></div>
          <div class="loader__square"></div>
          <div class="loader__square"></div>
        -->
      </div>
    </div>
  </div>

</template>

<script>

import anime from '@/assets/exhibition_3d/anime.min.js';
import imagesLoaded from '@/assets/exhibition_3d/imagesloaded.pkgd.min.js';
import test3d from '@/assets/exhibition_3d/main.js';

import MorphingGooeyAnimation from '@/components/MorphingGooeyAnimation.vue';

export default {
  name: 'Exhibition3D',
  components: {
    MorphingGooeyAnimation,
  },
  data() {
    return {
    };
  }, // data
  mounted() {
    // console.log('test', imagesLoaded)
    test3d(imagesLoaded, anime, this.loaderFinished);
  },
  watch: {
  },
  methods: {
    loaderFinished() {
      // alert('loadedFinished');
      this.$emit('loader-finished');
    },
  }, // methods
  computed: {
  }, // computed
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.exhibition-3d {

  // body {
    letter-spacing: 0.1em;
    color: #1f1f21;
    overflow: hidden;

    // height: 100vh;
    // background: #cecece;
    background: linear-gradient(#d0d0d0, 80%, #fff 100%); // #d0d0d0 is from floor (.room__side--bottom)
    height: 110vh;
  // }

  // .loader .test-effect {
  .loader {
    // background-color: #000;
    text-align: center;
    padding: 0 30px;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .test-effect {
      width: 100px;
      height: 150px;
    }
  }

  img.loader__mask-icon {
    // animation-name: anim-rotating;
    // animation-duration: 1s;
    // animation-timing-function: ease-in; // linear
    // animation-iteration-count: infinite;
    // animation-direction: alternate;

    animation: anim-loader 0.5s alternate infinite forwards;
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    // transform: rotateY(45deg);

    &:last-child {
      max-width: 60px;
      margin-left: 40px;
    }
  }

  @keyframes anim-rotating {
    0% { transform: rotateZ(0deg); }
    // 50% { transform: rotateZ(180deg); }
    // 75% { transform: rotateZ(270deg); }
    100% { transform: rotateZ(360deg); }
  }

  .loader {
    display: flex;
  }

  .loader .loader__square {
    width: 30px;
    height: 30px;
    margin: -30px 0.2em 0;
    border: 4px solid;
    background: #e9e9e9;
    animation: anim-loader 0.8s alternate infinite forwards;
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .loader .loader__square:nth-child(2) {
    border-radius: 50%;
    background: #c2292e;
    animation-delay: 0.15s;
  }

  .loader .loader__square:nth-child(3) {
    animation-delay: 0.3s;
  }

  @keyframes anim-loader {
    100% {
      transform: translate3d(0,30px,0) scale3d(0.9,0.9,1);
      // transform: translate3d(0,30px,0) scale3d(0.9,0.9,1) rotateY(45deg);
    }
  }

  h2,
  h3 {
    font-family: 'Josefin Sans', sans-serif;
  }

  *,
  *::after,
  *::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  // a {
  //   text-decoration: none;
  //   color: #000;
  //   outline: none;
  // }

  // a:hover,
  // a:focus {
  //   color: #c2292e;
  // }

  // .hidden {
  //   position: absolute;
  //   overflow: hidden;
  //   width: 0;
  //   height: 0;
  //   pointer-events: none;
  // }

  /* Icons */
  .icon {
    display: block;
    width: 1.5em;
    height: 1.5em;
    margin: 0 auto;
    fill: currentColor;
  }

  .btn {
    position: relative;
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
  }

  .btn:focus {
    outline: none;
  }

  .btn:hover {
    color: #c2292e;
  }

  .btn--nav {
    font-size: 2em;
    pointer-events: auto;
  }

  .nav-icon--right {
    transform: scale3d(-1,-1,1);
  }

  .nav__triangle,
  .nav__line {
    transition: transform 0.3s;
    fill: currentColor;
  }

  .btn--nav:hover .nav__triangle {
    transform: translate3d(-54px,0,0);
  }

  .btn--nav:hover .nav__line {
    transform: translate3d(17px,0,0);
  }

  .btn--info {
    margin: 0 2em 0 auto;
  }

  .btn--toggle.btn--active {
    z-index: 100;
    color: #fff;
  }

  .btn--toggle.btn--active:hover {
    color: #1f1f21;
  }

  .btn--toggle .icon:nth-child(2),
  .btn--toggle.btn--active .icon:first-child {
    display: none;
  }

  .btn--toggle.btn--active .icon:nth-child(2) {
    display: block;
  }

  /* two seats on each side for free space */

  .container {
    opacity: 0.3;

    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    perspective: 2000px;
  }

  .scroller {
    height: 100%;
    transform-style: preserve-3d;
  }

  .room {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    margin: -50vh 0 0 -50vw;
    pointer-events: none;
    opacity: 0;
    transform-style: preserve-3d;
  }

  .room--current {
    pointer-events: auto;
    opacity: 1;
  }

  .room__side {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-style: preserve-3d;

  }

  // .room__side--back {
  //   border-top: 1px solid #000;
  // }

  .room__side--left,
  .room__side--right {
    width: 4000px; /* depth */
    height: 100vh;
    background: #dbdbdb;

    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
  }

  .room__side--back {
    width: 100vw;
    height: 100vh;
    background: #e9e9e9;
    box-shadow: 0 0 0 2px #e9e9e9;
    transform: translate3d(0, 0, -4000px) rotate3d(1,0,0,0.1deg) rotate3d(1,0,0,0deg);
    /* Rotation due to rendering bug in Chrome when loader slides up (images seem cut off) */
  }

  .room__side--right {
    right: 0;
    // justify-content: flex-end;
    transform: rotate3d(0, 1, 0, -90.03deg);
    transform-origin: 100% 50%;
  }

  .room__side--left {
    // justify-content: flex-start;
    transform: rotate3d(0, 1, 0, 90deg);
    transform-origin: 0 50%;
  }

  .room__side--bottom {
    width: 100vw; /* depth */
    height: 4000px;
    background: #d0d0d0;
    transform: rotate3d(1, 0, 0, 90deg) translate3d(0, -4000px, 0);
    transform-origin: 50% 0%;

    display: none; // changed to gradient in body{}
  }

  .room__side--bottom {
    top: 100%;
  }

  /* Inner elements */
  .room__img {
    flex: none;
    max-width: 40%;
      max-height: 60%;
    margin: 0 5%;
    transform: translate3d(0,0,10px);
    backface-visibility: hidden;
  }

  /* Content */
  .content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    padding: 2vw;
  }

  /* Header */
  .codrops-header {
    display: flex;
    align-items: center;
  }

  .codrops-header__title {
    font-size: 1.165em;
    font-weight: normal;
    margin: 0.5em 0 0 0;
  }

  /* Top Navigation Style */
  .codrops-links {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2.75em;
    margin: 0 2em 0 0;
    text-align: center;
    white-space: nowrap;
    border: 2px solid;
    background: #fff;
  }

  .codrops-links::after {
    content: '';
    position: absolute;
    top: -5%;
    left: calc(50% - 1px);
    width: 2px;
    height: 110%;
    background: currentColor;
    transform: rotate3d(0,0,1,22.5deg);
  }

  .codrops-icon {
    display: inline-block;
    padding: 0 0.65em;
  }

  .subject {
    font-size: 1.5em;
    margin: 0 auto;
    color: #c2292e;
  }

  /* Location */
  .location {
    font-size: 1.165em;
    font-weight: normal;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    margin: 0 1.5em;
    padding: 6em 0;
    text-align: center;
    pointer-events: none;
    color: #c2292e;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
  }

  /* Slides */
  .slides {
    position: relative;
    flex: 1;
  }

  .slide {
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70vw;
    height: 100%;
    pointer-events: none;
    opacity: 0;


    // margin: 7vh 0 0 10vw;
    margin: 0vh 0 0 10vw;
  }

  .slide--current {
    pointer-events: auto;
    opacity: 1;
  }

  // https://stackoverflow.com/a/47511171
  @mixin stroke($color: #000, $size: 1px, $blur: 0px) {
    text-shadow:
      -#{$size} -#{$size} #{$blur} $color,
       0        -#{$size} #{$blur} $color,
       #{$size} -#{$size} #{$blur} $color,
       #{$size}  0        #{$blur} $color,
       #{$size}  #{$size} #{$blur} $color,
       0         #{$size} #{$blur} $color,
      -#{$size}  #{$size} #{$blur} $color,
      -#{$size}  0        #{$blur} $color;
  }

  .slide__name {
    // stroke outside
    @include stroke(rgba(0, 0, 0, 0.2), 1px, 3px);
    // text-shadow: -1px -1px 0 #000, 0 -1px 0 #000, 1px -1px 0 #000, 1px 0 0 #000, 1px 1px 0 #000, 0 1px 0 #000, -1px 1px 0 #000, -1px 0 0 #000;

    font-size: 10vw;
    line-height: 0.8;
    margin: 0;
    padding: 0 0 0 5vw;
    text-indent: -5vw;
    letter-spacing: -0.05em;
    // text-transform: lowercase;
    color: #fff;
  }

  .slide__collab-text {
    position: absolute;
    right: 0;
    font-family: "Josefin Sans", sans-serif;
    font-size: 26px;
    margin-top: 0;
    text-shadow: -1px -1px 1px #fff, 0 -1px 1px #fff, 1px -1px 1px #fff, 1px 0 1px #fff, 1px 1px 1px #fff, 0 1px 1px #fff, -1px 1px 1px #fff, -1px 0 1px #fff;
    color: #000;
    line-height: 35px;
    text-align: center;
  }

  .slide__title,
  .slide__date {
    text-align: right;
  }

  .slide__title {
    font-size: 3vw;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0.75em 0 0 0;


    // color: #fff;
    @include stroke(#fff, 1px, 1px);
    line-height: 1.2em;
  }

  .slide__date {
    font-size: 1.5vw;
    font-weight: bold;
    margin: 1.15em 0 0 0;
    text-transform: uppercase;
    color: #c2292e;
  }

  .slide__number {
    font-size: 0.4em;
    display: inline-block;
    margin: 0.5em 0 0 0;
    padding: 0.4em 0.5em 0.25em 0.5em;
    color: #fff;
    background: #c2292e;
  }

  /* Nav */
  .nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto 0 0 0;
    pointer-events: none;
  }

  /* Overlay with menu */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
  }

  .overlay--loader {
    z-index: 9000;
    background: #fff;
  }

  .overlay--info {
    background: rgba(23,94,236,0.4);
  }

  .overlay--menu {
    background: rgba(255,25,25,0.66);
  }

  .overlay--active {
    pointer-events: auto;
    opacity: 1;
  }

  .menu {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .menu__item {
    font-size: 7vh;
    margin: 0.25em 0;
    padding: 0;
    text-transform: lowercase;
  }

  .menu__item--current {
    font-weight: bold;
  }

  .menu__link {
    color: #fff;
  }

  .menu__link:focus,
  .menu__link:hover {
    color: #1f1f21;
  }

  .info {
    color: white;
    font-size: 1.5em;
    line-height: 1.4;
    width: 60vw;
    min-width: calc(320px - 2em);
    max-width: 900px;
    margin: 0;
    padding: 1em;
  }

  @media screen and (max-width: 50em) {
    .subject {
      display: none;
    }
    .codrops-links {
      margin: 0 0.5em 0 0;
    }
    .btn--info {
      margin-right: 1.25em;
    }
    .location {
      font-size: 0.85em;
      position: relative;
      height: auto;
      margin: 1em 0 0 0;
      padding: 0;
      text-align: left;
      -webkit-writing-mode: horizontal-tb;
      writing-mode: horizontal-tb;
    }
    .slide {
      width: 100%;

      // margin: 7vh 0 0 0;
      margin: 0;
    }
    .slide__name {
      // padding: 0 0 0 12vw;
      text-align: center;
      padding: 0;
      font-size: 20vw;
    }
    .slide__title {
      // font-size: 1.5em;
      // font-size: 2em;
      font-size: 6vw;

      text-align: center;
      margin-top: 33px;
    }
    .slide__date {
      font-size: 0.65em;
    }
    .nav {
      position: absolute;
      top: 7em;
      left: 0;
      width: 100%;
      padding: 1em;
    }
    .nav__triangle {
      transform: translate3d(-54px,0,0);
    }
    .nav__line {
      transform: translate3d(17px,0,0);
    }
    .btn--nav:hover {
      color: currentColor;
    }
    .info {
      font-size: 0.95em;
      width: 100vw;
    }
  }

  @media screen and (max-width: 650px) {
    height: 100vh; // .exhibition-3d

    .room__side--back .room__img {
      max-width: 70%;
    }

    .room__side--right {
      // right: 0;
      // justify-content: flex-end;
      // transform: rotate3d(0, 1, 0, -90.03deg);
      // transform-origin: 100% 50%;

      justify-content: center;
      transform: rotate3d(0, 1, 0, -76deg);
      transform-origin: 125% 50%;
      // background: red;

      img:nth-child(n+2) {
        display: none;
      }
    }

    .room__side--left {
      // justify-content: flex-start;
      // transform: rotate3d(0, 1, 0, 90deg);
      // transform-origin: 0 50%;

      justify-content: center;
      transform: rotate3d(0, 1, 0, 76deg);
      transform-origin: -25% 50%;
      // background: red;

      img:nth-child(n+2) {
        display: none;
      }
    }

    .room__side--back {
      img:nth-child(n+2) {
        display: none;
      }
    }
  }

  .room__side--left .room__img {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    &:nth-child(1) {
      box-shadow: -20px 0px 5px 2px #000, -40px 0px #000, -30px 0px 9px 0px #000;
    }

    &:nth-child(2) {
      box-shadow: -20px 0px 5px 2px #000, -50px 0px #000, -40px 0px 9px 0px #000;
    }
  }

  .room__side--right .room__img {
    // box-shadow: 20px 0px 5px 2px #000;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    &:nth-child(1) {
      box-shadow: 20px 0px 5px 2px #000, 50px 0px #000, 40px 0px 9px 0px #000;
    }

    &:nth-child(2) { // div
      box-shadow: 20px 0px 5px 2px #000, 40px 0px #000, 30px 0px 9px 0px #000;

      // box-shadow: 15px 0 5px 2px #000;
      // box-shadow: 30px 0px 0px 2px #000;
      // box-shadow: 20px 0px 5px 2px #000;
      // border-top-right-radius: 10px;
      // border-bottom-right-radius: 10px;
    }
  }
}
</style>
